import axios from "axios";
import { Url } from "src/config";

//Common api

axios.interceptors.request.use(
  (config) => {
    config.headers["access-token"] = `${sessionStorage.getItem('accessToken')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const commonApiService = {
  /**
   * used to get Sidebar menus
   * @returns SideBar menus
   */
  getSideBarMenus: async (id) => {
    try {
      const response = await axios.get(`${Url}/module/common/getsidebarmenu?id=${id}`);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
};

export default commonApiService;
